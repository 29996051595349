import { CelebrationAdminPanelConfig } from '../components/Celebrations';

function CelebrationsConfigRoute() {
  return <CelebrationAdminPanelConfig />;
}

export const Component = CelebrationsConfigRoute;

export function clientLoader() {
  return null;
}
